// cspell:ignore localforage
import localforage from 'localforage';

const VERSION_KEY = 'version';
const DEFAULT_VERSION = '1.0.0';

type Enum = Record<string, string | number>;
/**
 * Provides versioned, scoped access to known worker caches.
 */
class CacheManager<CacheType extends Enum> {
    constructor(
        /**
         * Enum/key:value pairs object of cache names and their keys.
         */
        private cacheType: CacheType,
        private worker: ServiceWorkerGlobalScope,
        private store: LocalForage,
        private prefix: string,
        private endpoints?: string[]
    ) {}

    public async getVersion() {
        try {
            return (await this.store.getItem<string>(VERSION_KEY)) || DEFAULT_VERSION;
        } catch {
            return DEFAULT_VERSION;
        }
    }

    public async setVersion(version: string) {
        const previousVersion = await this.getVersion();
        if (version === previousVersion) {
            return;
        }

        await this.store.setItem(VERSION_KEY, version);
        await this.clearStaleCaches();
    }

    /**
     * Cache slow endpoints preemptively before they would be requested during normal app navigation.
     */
    public async cacheEndpoints<K extends keyof CacheType>(
        cacheName: CacheType[K],
        apiRoot: string,
        authToken: string
    ) {
        if (!this.endpoints) {
            return;
        }

        const cache = await this.getCache(cacheName);
        const fullyQualifiedEndpoints = this.endpoints.map(endpoint => `${apiRoot}${endpoint}`);
        const requests = fullyQualifiedEndpoints.map(
            endpoint =>
                new Request(endpoint, {
                    headers: {
                        'x-tm-client-web': 'true',
                        'Jha-Treasury-ClientInfo': 'Web',
                        'Jha-Treasury-JhaAuthToken': authToken,
                    },
                })
        );

        return cache.addAll(requests);
    }

    /**
     * Removes versioned caches that are not current with the latest service worker.
     */
    public async clearStaleCaches() {
        const { caches } = this.worker;
        const cacheNames = getTypedKeys(this.cacheType).map(k => this.cacheType[k]);
        const versionedCacheKeys = await Promise.all(
            cacheNames.map(name => this.genCacheKey(name))
        );
        const currentCacheKeys = await caches.keys();
        const staleCacheKeys = currentCacheKeys.filter(key => !versionedCacheKeys.includes(key));
        const deletionTasks = staleCacheKeys.map(cacheToDelete => caches.delete(cacheToDelete));

        await Promise.all(deletionTasks);

        return this.worker.clients.claim();
    }

    public async getCache<K extends keyof CacheType>(cacheName: CacheType[K]) {
        const versionedKey = await this.genCacheKey(cacheName);
        const cache = await caches.open(versionedKey);

        return cache;
    }

    /**
     * Helper for generating a versioned cache key.
     * Mostly exists to ensure that the format is consistent everywhere it's used.
     */
    private async genCacheKey<K extends keyof CacheType>(cacheName: CacheType[K]) {
        const version = await this.getVersion();
        return `${this.prefix}-${cacheName}-${version}`;
    }
}

export function createCacheManager<CacheType extends Enum>(
    cacheType: CacheType,
    prefix: string,
    worker: ServiceWorkerGlobalScope,
    endpoints: string[] = []
) {
    const store = localforage.createInstance({
        storeName: `${prefix}-worker-metadata`,
    });

    return new CacheManager(cacheType, worker, store, prefix, endpoints);
}

function getTypedKeys<T extends object>(obj: T) {
    return Object.keys(obj) as (keyof T)[];
}
