/* eslint-disable no-use-before-define */
/// <reference no-default-lib="true"/>
/// <reference lib="WebWorker"/>
import { CacheGuard } from './cache-guard';
import { createCacheManager } from './cache-manager';
import { ResponseMeta } from './http-meta';

const endpointWhitelist = ['/achBanks'];

export enum PwaCache {
    Static = 'static',
    Runtime = 'runtime',
}

interface MessagePayload {
    version?: string;
    authToken?: string;
    apiRoot?: string;
}

/**
 * Aliases `self` mostly to enable better typing.
 */
// eslint-disable-next-line no-restricted-globals
const worker = self as unknown as ServiceWorkerGlobalScope;
const cm = createCacheManager(PwaCache, 'pwa', worker, endpointWhitelist);
const guard = new CacheGuard(worker, endpointWhitelist);

// The activate event occurs when a new version of the worker is invoked.
worker.addEventListener('activate', event => {
    event.waitUntil(cm.clearStaleCaches());
});

worker.addEventListener('fetch', event => {
    if (!guard.verifyRequest(event.request)) {
        return;
    }

    event.respondWith(onCacheableFetch(event));
});

worker.addEventListener('message', event => {
    const { version, authToken, apiRoot } = event.data as MessagePayload;

    if (version) {
        event.waitUntil(cm.setVersion(version));
    }

    if (authToken && apiRoot) {
        cm.cacheEndpoints(PwaCache.Runtime, apiRoot, authToken);
    }
});

/**
 * Responds to an HTTP request, using a cached response if available.
 *
 * If the response is cacheable--when it becomes available--
 * it is added to the cache to replace any old data.
 *
 * Uses the [stale-while-revalidate](https://jakearchibald.com/2014/offline-cookbook/#stale-while-revalidate)
 * pattern.
 */
async function onCacheableFetch(event: FetchEvent) {
    const { request } = event;
    const cachedResponse = await worker.caches.match(request);
    const responsePromise = fetch(request);

    responsePromise.then(response => tryCache(request, response));

    if (cachedResponse) {
        return cachedResponse;
    }

    // clone here so that the deferred work in tryCache() doesn't fail when calling clone() itself
    return (await responsePromise).clone();
}

async function tryCache(req: Request, res: Response) {
    const meta = new ResponseMeta(res);
    if (!guard.verifyResponse(res)) {
        return undefined;
    }

    const cache = meta.isJson
        ? await cm.getCache(PwaCache.Runtime)
        : await cm.getCache(PwaCache.Static);

    return cache.put(req, res.clone());
}
