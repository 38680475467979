/* eslint-disable no-use-before-define */
import { RequestMeta, ResponseMeta } from './http-meta';

const workerName = 'service-worker.js';

/**
 * Provides methods encapsulating the logic for verifying the cache eligibility
 * of `Request` and `Response` objects in a service worker.
 */
export class CacheGuard {
    constructor(
        private worker: ServiceWorkerGlobalScope,
        private endpointWhiteList: string[] = []
    ) {}

    public verifyRequest(req: Request) {
        const meta = new RequestMeta(req, this.worker);
        const { url } = req;

        // requests for HTTP verbs that result in side effects should not be cached
        if (!meta.isIdempotent) {
            return false;
        }

        // ignore requests to other origins (e.g., Google fonts)
        if (!meta.isSameOrigin) {
            return false;
        }

        // do not cache workers or Webpack updates
        if (meta.isWorker || url.endsWith('hot-update.js') || url.endsWith(workerName)) {
            return false;
        }

        // HTML should be treated as configuration and never cached:
        // https://immutablewebapps.com/#indexhtml-is-deployable-configuration
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (meta.isHtml) {
            return false;
        }

        return true;
    }

    public verifyResponse(resp: Response) {
        const { isValid, isHtml, isJson } = new ResponseMeta(resp);

        if (!isValid || isHtml) {
            return false;
        }

        if (isJson) {
            return isValidUrl(resp.url, this.endpointWhiteList);
        }

        return true;
    }
}

/**
 * Validates that a request or response URL is against
 * the channel API and on a whitelist of endpoints.
 */
function isValidUrl(url: string, whitelist: string[]) {
    // manifest can't be prefetched in the worker using addAll()
    // since the Azure function looks at the request referrer
    // to get the FI ID for branding assets
    if (url.endsWith('/api/manifest')) {
        return true;
    }

    return (
        url.includes('Jha.Web.Treasury.Channel.API') &&
        whitelist.some(endpoint => url.endsWith(endpoint))
    );
}
