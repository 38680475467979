/* eslint-disable max-classes-per-file */
export class RequestMeta {
    constructor(
        private req: Request,
        private worker: ServiceWorkerGlobalScope
    ) {}

    public readonly isIdempotent = this.req.method === 'GET';

    public readonly isSameOrigin = this.req.url.startsWith(this.worker.location.origin);

    public readonly isImage = this.req.destination === 'image';

    public readonly isHtml = this.req.destination === 'document';

    public readonly isJs = this.req.destination === 'script';

    public readonly isCss = this.req.destination === 'style';

    public readonly isFont = this.req.destination === 'font';

    public readonly isWorker = this.req.destination === 'worker';

    // https://developer.mozilla.org/en-US/docs/Web/API/Request/destination#sect1
    public readonly isStatic = this.req.destination !== '';
}

export class ResponseMeta {
    constructor(private res: Response) {}

    private readonly contentType = this.res.headers.get('Content-Type') || '';

    public readonly isJson = this.contentType.includes('application/json');

    public readonly isHtml = this.contentType.includes('text/html');

    public readonly isValid = this.res.status === 200;
}
